<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t('betDetail.title') }} #{{ item.bet.uuid || 'No transaction ID' }}
    </template>
    <div v-if="item.gameType === gamesTypes.WheelFortune">
      <div class="w-100">
        <p class="mb-1">{{ $t('betDetail.date') }}:</p>
        <h5 class="font-size-16">
          <div
            class="createdAt"
            @mouseover="handleOver"
            @mouseleave="handleLeave"
          >
            {{ moment(item.createdAt).format('DD/MM/YYYY | HH:mm:ss') }}
            <span class="badge badge-time bg-primary rounded-pill"
              >Server time:
              {{
                moment.utc(item.createdAt).format('DD/MM/YYYY | HH:mm:ss')
              }}</span
            >
          </div>
        </h5>
      </div>
      <div>
        <p class="mb-1">{{ $t('transactions.roundID') }} :</p>
        <h5 class="font-size-16">
          {{
            Object.entries(item).length && item.round
              ? item.round.identifierNumber
              : 'No round'
          }}
        </h5>
      </div>
      <div v-if="getJackpotWheel">
        <p>{{ $t('betDetail.jackpots') }}:</p>
        <span class="position-relative d-block" style="width: fit-content">
          <span class="badge badge-jack bg-warning rounded-pill"
            >X{{ getJackpotWheel.multiplier }}</span
          >
          <img
            :src="src[getJackpotWheel.number]"
            :title="`${getJackpotWheel.number}-WHEEL`"
            class="img-wheel"
          />
        </span>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t('betDetail.result') }}:</p>
        <h5 class="font-size-16">
          {{ formatResult(item) }}
        </h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t('betDetail.roulette') }} :</p>
        <h5 class="font-size-16">{{ item.game.name }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t('betDetail.user') }}:</p>
        <h5 class="font-size-16">{{ item.USERNAME }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t('tableReports.operator') }}:</p>
        <h5 class="font-size-16">{{ item.operator }}</h5>
      </div>
      <div v-if="Object.entries(item).length">
        <p class="mt-3 mb-1">{{ $t('filter.client') }}:</p>
        <h5 class="font-size-16">
          {{ item.client }}
        </h5>
      </div>
      <template v-if="bet">
        <div v-if="bet.length">
          <b-table :items="bet" :fields="fields" foot-clone>
            <template #cell(number)="data">
              <img
                :src="src[data.item.number]"
                :title="`${data.item.number}-WHEEL`"
                class="img-wheel"
              />
            </template>
            <template #foot(number)>
              <span><strong>TOTAL</strong></span>
            </template>
            <template #foot(amount)>
              <span
                ><strong>{{ getTotalBet.totalAmount }}</strong></span
              >
            </template>
            <template #foot(earnings)>
              <span
                ><strong>{{ getTotalBet.totalEarnings }}</strong></span
              >
            </template>
            <template #foot(jackpot)>
              <span>-</span>
            </template>
            <template #foot(earningsJackpot)>
              <span
                ><strong>{{ getTotalBet.totalEarningsJackpot }}</strong></span
              >
            </template>
          </b-table>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  image1,
  image2,
  image5,
  image8,
  image10,
  image15,
  image20,
  image30,
  image40,
} from '@/assets/images/wheel';

export default {
  computed: {
    formatResult() {
      return (item) => {
        console.log(
          'item.round.result',
          item.round.result,
          'getter',
          this.getResult
        );

        return typeof item.round.result === 'number'
          ? item.round.result
          : isNaN(+this.getResult)
            ? 'No hay resultado'
            : this.getResult === null
              ? 'No hay resultado'
              : +this.getResult;
      };
    },
    ...mapGetters({
      gamesTypes: 'reports/gamesTypes',
      fields: 'reports/getFields',
      bet: 'reports/getBet',
      getTotalBet: 'reports/getTotalBet',
      getJackpotWheel: 'reports/getJackpotWheel',
      getResult: 'reports/getResult',
    }),
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modal: false,
      images: {},
      src: {},
    };
  },
  methods: {
    async wheelImg(number) {
      const img = await import(
        `../../../../../assets/images/wheel/${number}-WHEEL.png`
      );
      this.images = { number, src: img.default };
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
  },
  watch: {
    images(newValue) {
      console.log('newValue', newValue);
      const { number, src } = newValue;
      if (!number) return;
      this.src[number] = src;
      console.log('', this.src);
    },
  },
  mounted() {
    this.src[1] = image1;
    this.src[2] = image2;
    this.src[5] = image5;
    this.src[8] = image8;
    this.src[10] = image10;
    this.src[15] = image15;
    this.src[20] = image20;
    this.src[30] = image30;
    this.src[40] = image40;
  },
};
</script>

<style lang="scss" scoped>
.img-wheel {
  height: 5rem;
  width: auto;
  display: block;
}
</style>
